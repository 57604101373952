import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import formatCurrency from "../helpers/formatCurrency.jsx";

library.add(fas);

SupplierCard.propTypes = {
  pk: PropTypes.number,
  legalName: PropTypes.string,
  taxId: PropTypes.string,
  totalInvoicesPendingAmount: PropTypes.number,
  currency: PropTypes.string,
  iconUrl: PropTypes.string,
  programName: PropTypes.string,
};

function SupplierCard({ pk, legalName, taxId, totalInvoicesPendingAmount, currency, iconUrl, programName }) {
  const navigate = useNavigate();

  return (
    <div className="col-lg-4 col-md-6 col-md-12 p-2">
      <div className="card">
        <div className="row align-items-center">
          <div className="col-2">
            <Avatar image={iconUrl} className="mr-3" />
          </div>
          <div className="col-10">
            <h5 className="m-0">{legalName}</h5>
            <small>{taxId}</small>
            <br />
            <small>{programName}</small>
          </div>
          <div className="col-12">
            <div className="row border-1 border-top mt-3">
              <div className="col-6 mt-3 text-start">
                <h5 className="m-0">{formatCurrency(totalInvoicesPendingAmount, currency)}</h5>
                <small>Total invoice value</small>
              </div>
              <div className="col-6 p-0 mt-3 text-end">
                <Button
                  label="View invoices"
                  iconPos="right"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "chevron-right"]} {...options.iconProps} />}
                  rounded
                  outlined
                  severity="grey"
                  onClick={() => navigate(`/buyer/supplier/show_invoices/${pk}`)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierCard;
