import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "primereact/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { currencies } from "../constants.jsx";
import { isNotEmpty, isEmpty } from "../helpers/isEmptyHelpers.jsx";
import { validateInteger, validateNumeric, validationPattern } from "../helpers/validationHelpers.jsx";

library.add(fas);

ProgramFormStepFees.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};

function ProgramFormStepFees({ register, errors, setValue, getValues, control, setError, clearErrors }) {
  const feeOptions = ["Platform Fee Percentage", "Program Fee"];
  const [feeOption, setFeeOption] = useState("");
  const [currencyValue, setCurrencyValue] = useState("");

  useEffect(() => {
    setCurrencyValue(getValues("program[currency]"));
    setFeeOption(getValues("fee_type"));
  }, [getValues]);

  return (
    <>
      <div className="form-row row mb-4">
        <div className="form-group col-md-12 mt-3">
          <label htmlFor="name" className="form-label">
            Program Name
          </label>
          <InputText
            name="program[name]"
            id="name"
            {...register("program[name]", { required: "Program name is required" })}
            className={`w-full ${errors.program?.name ? "p-invalid" : ""}`}
            placeholder="Enter program name"
          />
          {errors.program?.name && (
            <small className="p-error">{errors.program.name.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">
          <label htmlFor="programLimitAmount" className="form-label">
            Program Limit
          </label>
          <IconField iconPosition="left">
            <FontAwesomeIcon className="p-input-icon" icon={["fas", "dollar-sign"]} />
            <InputText
              name="program[program_limit_amount]"
              id="programLimitAmount"
              {...register("program[program_limit_amount]", { required: "Program limit is required" })}
              className={`w-full ${errors.program?.program_limit_amount ? "p-invalid" : ""}`}
              placeholder="Enter program limit"
              onChange={(e) => {
                validateInteger(e, setValue, "program[program_limit_amount]");
              }}
            />
          </IconField>
          {errors.program?.program_limit_amount && (
            <small className="p-error">{errors.program.program_limit_amount.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">
          {isEmpty(currencyValue) && (
            <>
              <label htmlFor="currency" className="form-label">Currency</label>
              <Controller
                id="currency"
                name="program[currency]"
                control={control}
                rules={{ required: "Currency is required" }}
                render={({ field, fieldState }) => (
                  <Dropdown
                    {...field}
                    value={field.value}
                    options={currencies}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    optionLabel="name"
                    optionValue="index"
                    placeholder="Select Currency"
                    className={`w-full ${fieldState.error ? "p-invalid" : ""}`}
                  />
                )}
              />
            </>
          )}
          {errors.program?.currency && (
            <small className="p-error">{errors.program.currency.message}</small>
          )}
        </div>
      </div>
      <h5 className="underline mt-3">The program fees</h5>
      <div className="col-md-12 mt-3">
        <div className="card form-content">
          <div className="card-body p-0">
            <div className="form-row row">
              <div className="form-group">
                <SelectButton
                  name="fee_type"
                  className="w-full flex justify-content-center mb-3"
                  value={feeOption}
                  onChange={(e) => {
                    setFeeOption(e.value);
                    setValue("fee_type", e.value);
                  }}
                  options={feeOptions}
                />
              </div>
              <div className="form-group col-md-6">                  
                <IconField iconPosition="left">
                  <FontAwesomeIcon className="p-input-icon" icon={["fas", "percent"]}/>
                  <InputText
                    disabled={feeOption && feeOption !== "Platform Fee Percentage"}
                    name="program_fee[platform_fee_percentage]"
                    id="platformFeePercentage"
                    {...register("program_fee[platform_fee_percentage]",
                      {
                        pattern: validationPattern.percentage,
                        validate: (value) => {
                          if (feeOption === "Platform Fee Percentage") {
                            return isNotEmpty(value) || "Platform fee percentage is required.";
                          }
                          return true;
                        },
                      })
                    }
                    className={`w-full ${errors.program_fee?.platform_fee_percentage ? "p-invalid" : ""}`}
                    placeholder="Enter percentage"
                  />
                </IconField>
                {errors.program_fee?.platform_fee_percentage && (
                  <small className="p-error">{errors.program_fee.platform_fee_percentage.message}</small>
                )}
              </div>
              <div className="form-group col-md-6">                  
                <IconField iconPosition="left">
                  <FontAwesomeIcon className="p-input-icon" icon={["fas", "dollar-sign"]}/>
                  <InputText
                    disabled={feeOption && feeOption !== "Program Fee"}
                    name="program_fee[program_fee_amount]"
                    id="programFeeAmount"
                    {...register("program_fee[program_fee_amount]",
                      {
                        validate: (value) => {
                          if (feeOption === "Program Fee") {
                            return isNotEmpty(value) || "Program fee amount is required.";
                          }
                          return true;
                        },
                      }
                    )}
                    className={`w-full ${errors.program_fee?.program_fee_amount ? "p-invalid" : ""}`}
                    placeholder="Enter amount"
                    onChange={(e) =>
                      validateNumeric(
                        e,
                        setValue,
                        setError,
                        clearErrors,
                        "program_fee[program_fee_amount]"
                      )
                    }
                  />
                </IconField>
                {errors.program_fee?.program_fee_amount && (
                  <small className="p-error">{errors.program_fee.program_fee_amount.message}</small>
                )}
              </div>
              <small className="mt-3 text-center soft-text">
                  only one option may be selected.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row row mb-4">
        <div className="form-group col-md-6 mt-3">                  
          <label htmlFor="bankChargeAmount" className="form-label">Bank charge amount</label>
          <IconField iconPosition="left">
            <FontAwesomeIcon className="p-input-icon" icon={["fas", "dollar-sign"]}/>
            <InputText
              name="program_fee[bank_charge_amount]"
              id="bankChargeAmount"
              {...register("program_fee[bank_charge_amount]",
                {
                  required: false,
                  pattern: validationPattern.decimal,
                }
              )}
              className={`w-full ${errors.program_fee?.bank_charge_amount ? "p-invalid" : ""}`}
              placeholder="Enter amount"
            />
          </IconField>
          {errors.program_fee?.bank_charge_amount && (
            <small className="p-error">{errors.program_fee.bank_charge_amount.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">                  
          <label htmlFor="rebatePercentage" className="form-label">Rebate percentage<span>(if applicable)</span></label>
          <IconField iconPosition="left">
            <FontAwesomeIcon className="p-input-icon" icon={["fas", "percent"]}/>
            <InputText
              name="program_fee[rebate_percentage]"
              id="rebatePercentage"
              {...register("program_fee[rebate_percentage]",
                {
                  required: false,
                  pattern: validationPattern.percentage,
                }
              )}
              className={`w-full ${errors.program_fee?.rebate_percentage ? "p-invalid" : ""}`}
              placeholder="Enter percentage"
            />
          </IconField>
          {errors.program_fee?.rebate_percentage && (
            <small className="p-error">{errors.program_fee.rebate_percentage.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">                  
          <label htmlFor="financialAgentRemunerationPercentage" className="form-label">Financial agent remuneration</label>
          <IconField iconPosition="left">
            <FontAwesomeIcon className="p-input-icon" icon={["fas", "percent"]}/>
            <InputText
              name="program_fee[financial_agent_remuneration_percentage]"
              id="financialAgentRemunerationPercentage"
              {...register("program_fee[financial_agent_remuneration_percentage]",
                {
                  required: false,
                  pattern: validationPattern.percentage,
                }
              )}
              className={`w-full ${errors.program_fee?.financial_agent_remuneration_percentage ? "p-invalid" : ""}`}
              placeholder="Enter percentage"
            />
          </IconField>
          {errors.program_fee?.financial_agent_remuneration_percentage && (
            <small className="p-error">{errors.program_fee.financial_agent_remuneration_percentage.message}</small>
          )}
        </div>
        <div className="form-group col-md-6 mt-3">                  
          <label htmlFor="financialRemunerationPercentage" className="form-label">Financial remuneration</label>
          <IconField iconPosition="left">
            <FontAwesomeIcon className="p-input-icon" icon={["fas", "percent"]}/>
            <InputText
              name="program_fee[financial_remuneration_percentage]"
              id="financialRemunerationPercentage"
              {...register("program_fee[financial_remuneration_percentage]",
                {
                  required: false,
                  pattern: validationPattern.percentage,
                }
              )}
              className={`w-full ${errors.program_fee?.financial_remuneration_percentage ? "p-invalid" : ""}`}
              placeholder="Enter percentage"
            />
          </IconField>
          {errors.program_fee?.financial_remuneration_percentage && (
            <small className="p-error">{errors.program_fee.financial_remuneration_percentage.message}</small>
          )}
        </div>
      </div>
    </>
  );
};

export default ProgramFormStepFees;
