import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import formatCurrency from "../helpers/formatCurrency.jsx";

library.add(fas);

SupplierProgramCard.propTypes = {
  pk: PropTypes.number,
  name: PropTypes.string,
  buyerDisplayName: PropTypes.string,
  buyerTaxId: PropTypes.string,
  totalInvoicesAmount: PropTypes.number,
  currency: PropTypes.string,
  iconUrl: PropTypes.string,
};

function SupplierProgramCard({ pk, name, buyerDisplayName, buyerTaxId, totalInvoicesAmount, currency, iconUrl }) {
  const navigate = useNavigate();

  return (
    <div className="col-12 p-1">
      <div className="card">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 my-2">
            <div className="row align-items-center">
              <div className="col-2">
                <Avatar image={iconUrl} className="mr-3" />
              </div>
              <div className="col-10">
                <h5>{name}</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 my-2">
            <div className="row align-items-center">
              <div className="col-6">
                <h5 className="m-0">{buyerDisplayName}</h5>
                <small>{buyerTaxId}</small>
              </div>
              <div className="col-6 text-start border-1 border-start">
                <h5 className="m-0">{formatCurrency(totalInvoicesAmount, currency)}</h5>
                <small>Total invoice amount</small>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 text-end my-2">
            <Button
              label="View invoices"
              iconPos="right"
              icon={(options) => <FontAwesomeIcon icon={["fas", "chevron-right"]}  {...options.iconProps} />}
              rounded
              outlined
              severity="grey"
              onClick={() => navigate(`/supplier/show_invoices/${pk}`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierProgramCard;
