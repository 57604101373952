import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Suspense, useState } from "react";
import apiAxios from "../../../axios.js";

import Header from "../../includes/Header.jsx";
import Footer from "../../includes/Footer.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import SimulateInvoiceSection from "../../section/SimulateInvoiceSection.jsx";
import PaidInvoiceSection from "../../section/PaidInvoiceSection.jsx";
import PendingInvoiceSection from "../../section/PendingInvoiceSection.jsx";
import DeclinedInvoiceSection from "../../section/DeclinedInvoiceSection.jsx";
import formatCurrency from "../../helpers/formatCurrency.jsx";
import ApprovedInvoiceSection from "../../section/ApprovedInvoiceSection.jsx";
import PastDueInvoiceSection from "../../section/PastDueInvoiceSection.jsx";
import LoadingFallback from "../../utils/Loading.jsx";

import { apiWrapper } from "../../common/apiWrapper.js";
import { isEmpty } from "../../helpers/isEmptyHelpers.jsx";

function SupplierInvoiceDetail() {
  const { pk } = useParams();
  const noInvoiceError = "No invoice found";

  const [invoiceResource, setInvoiceResource] = useState(() => apiWrapper(() =>
    apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/supplier/program/invoice?pk=${pk}`)
  ));

  const InvoiceDetails = ({ resource }) => {
    const [selectedFee, setSelectedFee] = useState(null);
    const response = resource.read();
    let invoice = {};

    if (isEmpty(response?.data?.invoice)) {
      ShowAlert("warning", response?.error ?? noInvoiceError);
    } else {
      invoice = response?.data?.invoice;
    }

    const availableFeePercentages = invoice.simulated_fees.map((fee) => ({
      label: `${fee.percentage_requested}% (${formatCurrency(fee.expected_amount, invoice.currency)})`,
      value: fee.percentage_requested,
    }));

    if (availableFeePercentages.length && isEmpty(selectedFee)) {
      setSelectedFee(availableFeePercentages[0].value);
    }

    const renderInvoiceSection = (status) => {
      switch (status) {
        case "approved":
          return <ApprovedInvoiceSection invoice={invoice} />;
        case "paid":
          return <PaidInvoiceSection invoice={invoice} />;
        case "request_is_sent":
          return <PendingInvoiceSection invoice={invoice} />;
        case "declined":
          return <DeclinedInvoiceSection invoice={invoice} />;
        case "available":
          return (
            <SimulateInvoiceSection
              availableFeePercentages={availableFeePercentages}
              defaultFee={selectedFee}
              invoice={invoice}
              fetchInvoice={() => {
                setInvoiceResource(() => apiWrapper(() =>
                  apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/supplier/program/invoice?pk=${pk}`)
                ));
              }}
            />
          );
        case "past_due":
        case "past_due_out_of_network":
          return <PastDueInvoiceSection invoice={invoice} />;
        default:
          return null;
      }
    };

    return (
      <>
        {renderInvoiceSection(invoice.humanized_status)}
      </>
    );
  };

  InvoiceDetails.propTypes = {
    resource: PropTypes.shape({
      read: PropTypes.func.isRequired
    }).isRequired,
  };

  return (
    <>
      <Header />
      <main className="footer-top-container">
        <div className="container-md my-4">
          <Suspense fallback={<LoadingFallback />}>
            <InvoiceDetails resource={invoiceResource} />
          </Suspense>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default SupplierInvoiceDetail;
