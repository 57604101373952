import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";


const DEFAULT_LOGO = "/images/lion.png";
const DEFAULT_THEME = "/themes/default.css";

const setLogo = (logo) => {
  return logo ? `/images/${logo}.png` : DEFAULT_LOGO;
};

const setTheme = (theme) => {
  return theme ? `/themes/${theme}.css` : DEFAULT_THEME;
};

const DEFAULT_BRAND = {
  logo: DEFAULT_LOGO,
  theme: DEFAULT_THEME,
};

const brandStore = create(
  persist(
    (set) => ({
      brand: DEFAULT_BRAND,
      setBrand: (branding) => set({
        brand: {
          logo: setLogo(branding.logo),
          theme: setTheme(branding.theme),
        },
      }),
      clearBrand: () => set({ brand: DEFAULT_BRAND }),
    }),
    {
      name: "brand-storage",
      getStorage: createJSONStorage(() => localStorage),
    }
  )
);

export default brandStore;
