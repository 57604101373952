import { Link } from "react-router-dom";
import Footer from "../../includes/Footer.jsx";
import { swiftlyContact } from "../../constants.jsx";

const TermsAndConditions = () => {

  const privacyLink = <Link to={"/privacy"} onClick={() => {}}>
    Privacy Policy
  </Link>;

  return (
    <>
      <main className="footer-top-container-no-header d-flex justify-content-center align-items-center">
        <div className="container-xl mt-4">
          <div className="card page-content">
            <div className="card-body">
              <div>
                <h1>Terms and Conditions</h1>
                <p><strong>Effective Date:</strong> 01-02-2025</p>
                <p><strong>Last Updated:</strong> 27-02-2025</p>

                <h4>1. Introduction</h4>
                <p>Welcome to <strong>Swiftly</strong> (“we,” “our,” or “us”). Swiftly is a single-page web application (SPA) that provides supply chain financing solutions in <strong>Botswana</strong>. By using our platform, you (“user,” “you,” or “your”) agree to these Terms and Conditions (“Terms”). If you do not agree, <strong>do not use Swiftly</strong>.</p>

                <h4>2. Eligibility</h4>
                <p>To use Swiftly, you must:</p>
                <ul>
                  <li>Be at least <strong>18 years old</strong> (or the legal age in Botswana).</li>
                  <li>Be <strong>legally capable</strong> of entering into binding contracts.</li>
                  <li>Use Swiftly <strong>only for lawful business transactions</strong> within Botswana.</li>
                </ul>
                <p>We may <strong>suspend or terminate</strong> accounts that do not meet these requirements.</p>

                <h4>3. Services Provided</h4>
                <p>Swiftly facilitates <strong>supply chain financing</strong> by connecting:</p>
                <ul>
                  <li><strong>Buyers</strong> who need financing for purchases.</li>
                  <li><strong>Suppliers</strong> who sell goods/services on credit.</li>
                  <li><strong>Financial institutions</strong> that provide early payment options.</li>
                </ul>
                <p>Swiftly does <strong>not</strong> provide loans directly but acts as a platform for transactions.</p>

                <h4>4. Account Registration</h4>
                <ul>
                  <li>You must provide <strong>accurate and complete</strong> information.</li>
                  <li>You are responsible for maintaining <strong>confidentiality</strong> of your login credentials.</li>
                  <li>Any unauthorized use of your account should be reported immediately.</li>
                </ul>

                <h4>5. Fees and Payments</h4>
                <ul>
                  <li>Service fees may apply for financing facilitation.</li>
                  <li>Any fees will be disclosed <strong>before</strong> transactions.</li>
                  <li>Payments are processed through <strong>[specify payment gateway]</strong>.</li>
                  <li>Non-payment may lead to account suspension.</li>
                </ul>

                <h4>6. User Responsibilities</h4>
                <ul>
                  <li>You agree <strong>not to engage in fraudulent activities</strong> or misrepresent financial information.</li>
                  <li>You will <strong>comply with all applicable laws and regulations</strong> in Botswana.</li>
                  <li>You must <strong>not use Swiftly for money laundering, terrorism financing, or other illegal purposes</strong>.</li>
                </ul>

                <h4>7. Data Privacy</h4>
                <p>Swiftly collects and processes user data in compliance with <strong>Botswana’s Data Protection Act</strong>. We do not sell your data to third parties.</p>
                <p>For details, see our {privacyLink}.</p>
                <h4>8. Intellectual Property</h4>
                <p>Swiftly and its content (logos, trademarks, software) are <strong>our intellectual property</strong>. You may <strong>not</strong> copy, modify, or distribute any part of Swiftly without permission.</p>

                <h4>9. Limitation of Liability</h4>
                <ul>
                  <li>We provide Swiftly <strong>“as is”</strong> without warranties.</li>
                  <li>We are <strong>not liable</strong> for:
                    <ul>
                      <li>Financial losses due to transactions on Swiftly.</li>
                      <li>System downtime, cyberattacks, or service disruptions.</li>
                      <li>Errors or inaccuracies in user-submitted data.</li>
                    </ul>
                  </li>
                </ul>

                <h4>10. Termination</h4>
                <p>We may <strong>suspend or terminate</strong> accounts for <strong>violations of these Terms</strong>. You may close your account at any time.</p>

                <h4>11. Dispute Resolution</h4>
                <p>Any disputes will be resolved <strong>first through negotiation</strong>. If unresolved, disputes will be handled in <strong>Botswana’s courts</strong>.</p>

                <h4>12. Changes to Terms</h4>
                <p>We may update these Terms at any time. You will be notified of <strong>significant changes</strong>.</p>

                <h4>13. Contact Information</h4>
                <p>For questions, contact us at:</p>
                <p><strong>Swiftly Support</strong></p>
                <p><strong>Email:</strong> { swiftlyContact.EMAIL }</p>
                <p><strong>Phone:</strong> { swiftlyContact.PHONE }</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
