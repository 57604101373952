import { useParams, useNavigate } from "react-router-dom";
import { useState, useRef, Suspense, useEffect, useCallback } from "react";
import apiAxios from "../../../axios.js";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Toolbar } from "primereact/toolbar";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { TabPanel, TabView } from "primereact/tabview";

import brandStore from "../../../stores/BrandStore.js";
import Header from "../../includes/Header.jsx";
import Footer from "../../includes/Footer.jsx";
import { isNotEmpty } from "../../helpers/isEmptyHelpers.jsx";
import formatStatus from "../../helpers/formatStatus.jsx";
import formatDate from "../../helpers/formatDate.jsx";
import formatCurrency from "../../helpers/formatCurrency.jsx";
import formatDecimal from "../../helpers/formatDecimal.jsx";
import StatsRowTemplate from "../../tables/templates/StatsRowTemplate.jsx";
import formatPercentage from "../../helpers/formatPercentage.jsx";
import LoadingFallback from "../../utils/Loading.jsx";

import { apiWrapper } from "../../common/apiWrapper.js";
import InvoiceFilterActions from "../../actions/InvoiceFilterActions.jsx";
import sortHelper from "../../helpers/sortHelper.jsx";
import SaveAsExcelHelper from "../../helpers/SaveAsExcelHelper.jsx";

library.add(fas);

function SupplierInvoices() {
  const { pk } = useParams();

  const dashboardStatDataWrapper = apiWrapper(() =>
    apiAxios.get(
      `${process.env.REACT_APP_BASE_API_URL}/api/v1/supplier/program/invoices/dashboard?program_pk=${pk}`
    )
  );

  const StatsSectionWithSuspense = () => {
    return (
      <Suspense fallback={<LoadingFallback />}>
        <StatsRowTemplate dashboardStatDataWrapper={dashboardStatDataWrapper} />
      </Suspense>
    );
  };

  function InvoiceContent() {
    const navigate = useNavigate();
    const setBrand = brandStore((state) => state.setBrand);
    const clearBrand = brandStore((state) => state.clearBrand);
    const [activeIndex, setActiveIndex] = useState(0);
    const pendingInvoicesDataTableReference = useRef(null);
    const pastInvoicesDataTableReference = useRef(null);
    const [loading, setLoading] = useState(false);
    const [totalPendingRecordCount, setTotalPendingRecordCount] = useState(0);
    const [totalPastRecordCount, setTotalPastRecordCount] = useState(0);
    const [pendingInvoices, setPendingInvoices] = useState([]);
    const [pastInvoices, setPastInvoices] = useState([]);
    const [program, setProgram] = useState({});
    const [isClearFilterVisible, setIsClearFilterVisible] = useState(false);
    const [tableLazyState, setTableLazyState] = useState({
      first: 0,
      rows: 25,
      page: 1,
      sortField: null,
      sortOrder: null,
      filters: {}
    });

    const updateTableLazyState = (newState) => {
      setTableLazyState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    };

    const onPage = (event) => {
      updateTableLazyState({
        first: event.first,
        rows: event.rows,
        page: event.page + 1,
      });
    };

    const onSort = (event) => {
      const { sortField, sortOrder } = event;

      updateTableLazyState({
        sortField,
        sortOrder,
      });
    };

    const onSearch = async (searchTerm) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          search_term: searchTerm,
          /* eslint-enable camelcase */
        },
      });
    };

    const onDateChange = (startDate, endDate) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          start_date: startDate,
          end_date: endDate,
          /* eslint-enable camelcase */
        },
      });
    };

    const onAmountChange = (minAmount, maxAmount) => {
      updateTableLazyState({
        first: 0,
        page: 1,
        filters: {
          ...tableLazyState.filters,
          /* eslint-disable camelcase */
          min_amount: minAmount,
          max_amount: maxAmount,
          /* eslint-enable camelcase */
        },
      });
    };

    const loadInvoiceTable = useCallback(async () => {
      setLoading(true);
      const requestParams = {
        /* eslint-disable camelcase */
        program_pk: pk,
        /* eslint-enable camelcase */
        page: tableLazyState.page,
        pageSize: tableLazyState.rows,
        sortField: tableLazyState.sortField,
        sortOrder: tableLazyState.sortOrder,
        ...tableLazyState.filters
      };

      const invoiceDataWrapper = apiWrapper(() =>
        apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/supplier/program/invoices`, {
          params: requestParams
        })
      );

      try {
        const response = await invoiceDataWrapper.read();
        if (response?.data) {
          setPendingInvoices(response.data.pending || []);
          setPastInvoices(response.data.history || []);
          setTotalPendingRecordCount(response.data.pending.length || 0);
          setTotalPastRecordCount(response.data.history.length || 0);
          setProgram(response.data.program || {});
          const branding = response.data.branding;
          if (isNotEmpty(branding) && (branding.logo || branding.theme)) {
            setBrand(branding);
          }
        }
        setLoading(false);
      } catch (promiseOrError) {
        if (promiseOrError instanceof Promise) {
          promiseOrError.then(async () => {
            try {
              const response = await invoiceDataWrapper.read();
              if (response?.data) {
                setPendingInvoices(response.data.pending || []);
                setPastInvoices(response.data.history || []);
                setTotalPendingRecordCount(response.data.pending.length || 0);
                setTotalPastRecordCount(response.data.history.length || 0);
                setProgram(response.data.program || {});
                const branding = response.data.branding;
                if (isNotEmpty(branding) && (branding.logo || branding.theme)) {
                  setBrand(branding);
                }
              }
            } catch (error) {
              setPendingInvoices([]);
              setPastInvoices([]);
              setTotalPendingRecordCount(0);
              setTotalPastRecordCount(0);
              setProgram({});
              clearBrand();
            } finally {
              setLoading(false);
            }
          });
        } else {
          setPendingInvoices([]);
          setPastInvoices([]);
          setTotalPendingRecordCount(0);
          setTotalPastRecordCount(0);
          setProgram({});
          clearBrand();
          setLoading(false);
        }
      }
    }, [tableLazyState, setBrand, clearBrand]);

    useEffect(() => {
      loadInvoiceTable();
    }, [loadInvoiceTable]);

    const sortedPendingInvoices = sortHelper(pendingInvoices, tableLazyState.sortField, tableLazyState.sortOrder);
    const sortedPastInvoices = sortHelper(pastInvoices, tableLazyState.sortField, tableLazyState.sortOrder);

    const exportExcel = () => {
      let formattedInvoice = [];
      if (activeIndex === 0) {
        formattedInvoice = pendingInvoices.map((invoice) => {
          return {
            "Invoice": invoice.invoice_number,
            "Due date": formatDate(invoice.due_at),
            "Currency": invoice.currency,
            "Original value": formatDecimal(invoice.amount),
            "Early Payment Expected": invoice.expected_amount ? formatDecimal(invoice.expected_amount) : "",
            "Discount": invoice.discount_amount ? formatDecimal(invoice.discount_amount) : "",
            "Status": invoice.humanized_status,
          };
        });
      } else if (activeIndex === 1) {
        formattedInvoice = pastInvoices.map((invoice) => {
          return {
            "Invoice": invoice.invoice_number,
            "Due date": formatDate(invoice.due_at),
            "Currency": invoice.currency,
            "Original value": formatDecimal(invoice.amount),
            "Early Payment": invoice.expected_amount ? formatDecimal(invoice.expected_amount) : "",
            "Discount": invoice.discount_amount ? formatDecimal(invoice.discount_amount) : "",
            "Status": invoice.humanized_status,
          };
        });
      }
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(formattedInvoice);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array"
        });

        SaveAsExcelHelper(excelBuffer, "invoices");
      });
    };

    const leftToolbarHeaderTemplate = () => {
      return (
        <div className="flex flex-wrap gap-2">
          <h1>{program.name}</h1>
        </div>
      );
    };

    const simulateInvoiceTemplate = (rowData) => {
      if (rowData.humanized_status == "available" || rowData.humanized_status == "expired") {
        return (
          <Button
            className="has-icon"
            label="Simulate Invoice"
            icon={(options) => <FontAwesomeIcon icon={["fas", "play"]} {...options.iconProps} />}
            rounded
            outlined
            severity="success"
            onClick={() => navigate(`/supplier/invoice/show_invoice_detail/${rowData.pk}`)}
          />
        );
      } else {
        return (
          <Button
            className="has-icon"
            label="View Invoice"
            icon={(options) => <FontAwesomeIcon icon={["fas", "play"]} {...options.iconProps} />}
            rounded
            outlined
            severity="grey"
            onClick={() => navigate(`/supplier/invoice/show_invoice_detail/${rowData.pk}`)}
          />
        );
      }
    };

    const rightToolbarOptionsTemplate = () => {
      return (
        <Button
          className="has-icon"
          label="Export table"
          icon={(options) => <FontAwesomeIcon icon={["fas", "file-export"]} {...options.iconProps} />}
          rounded
          severity="dark"
          onClick={exportExcel}
        />
      );
    };

    return (
      <div className="card page-content">
        <div className="card-body">
          <Toolbar
            className="mb-4"
            start={leftToolbarHeaderTemplate}
            end={
              <InvoiceFilterActions
                onSearch={onSearch}
                onDateChange={onDateChange}
                onAmountChange={onAmountChange}
                updateTableLazyState={updateTableLazyState}
                setIsClearFilterVisible={setIsClearFilterVisible}
                isClearFilterVisible={isClearFilterVisible}
              />}
          />
          <StatsSectionWithSuspense />
          <div className="position-relative">
            <TabView
              className="m-4"
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              <TabPanel
                header="Pending invoices"
                leftIcon={<FontAwesomeIcon icon={["fas", "file-download"]} className="me-1" />}
              >
                <DataTable
                  ref={pendingInvoicesDataTableReference}
                  value={sortedPendingInvoices}
                  dataKey="invoice_number"
                  lazy
                  removableSort
                  paginator
                  rows={tableLazyState.rows}
                  rowsPerPageOptions={[25, 50, 100]}
                  paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  first={tableLazyState.first}
                  totalRecords={totalPendingRecordCount}
                  onPage={onPage}
                  onSort={onSort}
                  sortField={tableLazyState.sortField}
                  sortOrder={tableLazyState.sortOrder}
                  loading={loading}
                >
                  <Column field="invoice_number" header="Invoice" sortable style={{ minWidth: "10rem" }}></Column>
                  <Column field="due_at" body={(rowData) => formatDate(rowData.due_at)} header="Due date" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="amount" body={(rowData) => formatCurrency(rowData.amount, program.currency)} header="Original value" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="expected_amount" body={(rowData) => rowData.expected_amount ? `${formatCurrency(rowData.expected_amount, program.currency)} (${formatPercentage(rowData.early_payment_request.percentage_requested)})` : ""} header="Early payment Expected" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="discount_amount" body={(rowData) => rowData.discount_amount ? formatCurrency(rowData.discount_amount, program.currency) : ""} header="Discount" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="humanized_status" header="Status" body={(rowData) => formatStatus(rowData.humanized_status)} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column field="" header="" body={(rowData) => simulateInvoiceTemplate(rowData)} style={{ maxWidth: "10rem" }}></Column>
                </DataTable>
              </TabPanel>
              <TabPanel
                header="History"
                leftIcon={<FontAwesomeIcon icon={["fas", "file"]} className="me-1" />}
              >
                <DataTable
                  ref={pastInvoicesDataTableReference}
                  value={sortedPastInvoices}
                  dataKey="invoice_number"
                  lazy
                  removableSort
                  paginator
                  rows={tableLazyState.rows}
                  rowsPerPageOptions={[25, 50, 100]}
                  paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  first={tableLazyState.first}
                  totalRecords={totalPastRecordCount}
                  onPage={onPage}
                  onSort={onSort}
                  sortField={tableLazyState.sortField}
                  sortOrder={tableLazyState.sortOrder}
                  loading={loading}
                >
                  <Column field="invoice_number" header="Invoice" sortable style={{ minWidth: "10rem" }}></Column>
                  <Column field="due_at" body={(rowData) => formatDate(rowData.due_at)} header="Due date" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="amount" body={(rowData) => formatCurrency(rowData.amount, program.currency)} header="Original value" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="expected_amount" body={(rowData) => rowData.expected_amount ? `${formatCurrency(rowData.expected_amount, program.currency)} (${formatPercentage(rowData.early_payment_request.percentage_requested)})` : ""} header="Early payment" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="discount_amount" body={(rowData) => rowData.discount_amount ? formatCurrency(rowData.discount_amount, program.currency) : ""} header="Discount" sortable style={{ maxWidth: "10rem" }}></Column>
                  <Column field="humanized_status" header="Status" body={(rowData) => formatStatus(rowData.humanized_status)} sortable style={{ maxWidth: "8rem" }}></Column>
                </DataTable>
              </TabPanel>
            </TabView>
            <div className="overlay-button mx-3">
              {rightToolbarOptionsTemplate()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <main className="footer-top-container">
        <div className="container my-4">
          <InvoiceContent />
        </div>
      </main>
      <Footer />
    </>
  );
}

export default SupplierInvoices;
