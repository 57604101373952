import { swiftlyContact } from "../../constants.jsx";
import Footer from "../../includes/Footer.jsx";
    
const Privacy = () => {
  return (
    <>
      <main className="footer-top-container-no-header d-flex justify-content-center align-items-center">
        <div className="container-xl mt-4">
          <div className="card page-content">
            <div className="card-body">
              <div>
                <h1>Privacy Policy for Swiftly</h1>
                <p><strong>Effective Date:</strong> 01-02-2025</p>

                <p>Swiftly (“we,” “our,” “us”) is committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines the types of personal information we collect, how we use and protect that information, and your rights regarding your data.</p>

                <p>By accessing or using the Swiftly platform, you agree to the collection and use of your information in accordance with this Privacy Policy.</p>

                <h4>1. Information We Collect</h4>
                <ul>
                  <li><strong>Personal Identification Information:</strong> Name, email address, phone number, etc.</li>
                  <li><strong>Business Information:</strong> Company name, registration number, financial data.</li>
                  <li><strong>Transaction Data:</strong> Details of financial transactions, invoices, payments.</li>
                  <li><strong>Usage Data:</strong> Device information, browser type, and usage patterns.</li>
                </ul>

                <h4>2. How We Use Your Information</h4>
                <p>We may use the information we collect for the following purposes:</p>
                <ul>
                  <li>To provide and maintain our supply chain financing services.</li>
                  <li>To process transactions and manage your account.</li>
                  <li>To communicate with you, including sending updates and support messages.</li>
                  <li>To improve and personalize our platform&#39;s functionality.</li>
                  <li>To comply with legal obligations and regulatory requirements.</li>
                </ul>

                <h4>3. Data Sharing and Disclosure</h4>
                <p>We do not sell, rent, or lease your personal data to third parties. However, we may share your information in the following circumstances:</p>
                <ul>
                  <li><strong>Service Providers:</strong> We may engage third-party service providers for services such as payment processing and customer support.</li>
                  <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or legal request.</li>
                  <li><strong>Business Transfers:</strong> In case of a merger, acquisition, or sale of Swiftly’s assets, your information may be transferred.</li>
                </ul>

                <h4>4. Data Security</h4>
                <p>We take reasonable measures to protect your personal information, including encryption and secure communication channels. However, no method of data transmission can be guaranteed to be 100% secure.</p>

                <h4>5. Data Retention</h4>
                <p>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, and resolve disputes.</p>

                <h4>6. Your Rights</h4>
                <p>You have the following rights regarding your personal information:</p>
                <ul>
                  <li><strong>Access:</strong> Request access to your personal information.</li>
                  <li><strong>Correction:</strong> Request corrections to inaccurate or incomplete information.</li>
                  <li><strong>Deletion:</strong> Request the deletion of your personal information.</li>
                  <li><strong>Withdrawal of Consent:</strong> Withdraw consent for data processing.</li>
                  <li><strong>Objection to Processing:</strong> Object to the processing of your personal data.</li>
                </ul>

                <h4>7. Cookies and Tracking Technologies</h4>
                <p>We may use cookies and other tracking technologies to enhance your experience on the platform. You can control cookie settings through your browser settings.</p>

                <h4>8. International Transfers</h4>
                <p>Your personal information may be transferred and processed outside of Botswana in accordance with applicable data protection laws.</p>

                <h4>9. Children&#39;s Privacy</h4>
                <p>Our platform is not intended for children under 13, and we do not knowingly collect personal data from children.</p>

                <h4>10. Changes to This Privacy Policy</h4>
                <p>We reserve the right to update this Privacy Policy. Any changes will be posted on this page, and we encourage you to review it periodically.</p>

                <h4>11. Contact Us</h4>
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                <p><strong>Swiftly Support</strong></p>
                <p><strong>Email:</strong> { swiftlyContact.EMAIL }</p>
                <p><strong>Phone:</strong> { swiftlyContact.PHONE }</p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Privacy;
