import PropTypes from "prop-types";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import brandStore from "../../stores/BrandStore.js";
import formatCurrency from "../helpers/formatCurrency.jsx";
import SupplierInvoiceFee from "../utils/SupplierInvoiceFee.jsx";
import SupplierInvoiceTopSection from "./SupplierInvoiceTopSection.jsx";
import formatPercentage from "../helpers/formatPercentage.jsx";
import { camelizeWithSpaces } from "../helpers/camelizeWithSpaces.jsx";

library.add(fas);

PaidInvoiceSection.propTypes = {
  invoice: PropTypes.object,
};

function PaidInvoiceSection({ invoice }) {
  const brandLogo = brandStore((state) => state.brand.logo);

  return (
    <div className="card card-invoice p-0">
      <div className="card-header">
        <img src={brandLogo} alt="Logo" className="logo-overlay" />
        <p className="m-0">This request has been paid and will reflect in your registered bank account within 3 business days.</p>
        <p className="bold-xxl">{formatCurrency(invoice.early_payment_request?.expected_amount, invoice.currency)}</p>
      </div>
      <div className="card-body">
        <SupplierInvoiceTopSection invoice={invoice} />
        <hr />
        <SupplierInvoiceFee invoice={invoice} earlyPaymentFees={invoice.early_payment_request.early_payment_request_fees} />
        <div className="d-flex justify-content-between mt-3 mb-5">
          <div>
            <h6>Requested Amount</h6>
            <small>You can only make one early payment request for an invoice</small>
          </div>
          <div className="d-flex">
            <p className="bold-sm-light">{formatPercentage(parseInt(invoice.early_payment_request?.percentage_requested))}</p>
            <p className="bold-sm-light">({formatCurrency(invoice.early_payment_request?.expected_amount, invoice.currency)})</p>
          </div>
        </div>
        <Button
          className="p-button-wide"
          icon={(options) => <FontAwesomeIcon icon={["fas", "circle-check"]}  {...options.iconProps} />}
          type="button"
          label={camelizeWithSpaces(invoice.humanized_status)}
          rounded
          severity="success"
        />
      </div>
    </div>
  );
};

export default PaidInvoiceSection;
