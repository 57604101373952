import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import Header from "../../includes/Header.jsx";
import Footer from "../../includes/Footer.jsx";
import apiAxios from "../../../axios.js";
import { isNotEmpty } from "../../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { camelizeWithSpaces } from "../../helpers/camelizeWithSpaces.jsx";
import formatDate from "../../helpers/formatDate.jsx";
import formatCurrency from "../../helpers/formatCurrency.jsx";
import { triggerFileDownload } from "../../helpers/triggerFileDownload.jsx";

function BuyerSupplierInvoiceDetail() {
  const { pk } = useParams();
  const [invoice, setInvoice] = useState({});
  const unknownError = "An unknown error occurred";
  const noInvoiceError = "No invoice found";

  const leftToolbarHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h1>Invoice details</h1>
      </div>
    );
  };

  const handleDownload = () => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/buyer/supplier/download_invoice?invoice_pk=${invoice.pk}`, {
        responseType: "blob",
      })
      .then((response) => {
        triggerFileDownload(response.data, `${invoice.invoice_number}.pdf`);
      })
      .catch((error) => {
        ShowAlert("error", error);
      });
  };

  const rightToolbarHeaderTemplate = () => {
    return (
      <>
        <Button 
          className="has-icon mr-2"
          label="Download"
          icon={(options) => <FontAwesomeIcon icon={["fas", "file-download"]}  {...options.iconProps} /> }
          rounded
          outlined
          severity="grey"
          onClick={handleDownload}
        />      
        <Button 
          className="has-icon"
          label="Print"
          icon={(options) => <FontAwesomeIcon icon={["fas", "print"]}  {...options.iconProps} /> } 
          rounded
          outlined
          severity="grey"
          onClick={() => window.print()}
        />      
      </>
    );
  };

  useEffect(() => {
    apiAxios
      .get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/buyer/show_invoice?invoice_pk=${pk}`)
      .then((response) => {
        if (isNotEmpty(response.data.invoice)) {
          setInvoice(response.data.invoice);
        } else {
          ShowAlert("warning", response?.error ?? noInvoiceError);
        }
      })
      .catch((error) => {
        var errorResponse = error.response?.data;
        ShowAlert("error", errorResponse?.error || unknownError);
      });
  }, [pk]);

  return (
    <>
      <Header />
      <main className="footer-top-container">
        <div className="container-md my-4">
          <Toolbar className="px-0" start={leftToolbarHeaderTemplate} end={rightToolbarHeaderTemplate}></Toolbar>
          <div className="card">
            <div className="card-body mt-3">
              <h5 className="underline">General Info</h5>
              <div className="info-text flex flex-wrap gap-4">
                <div className="flex-1 mt-3">
                  <h6>Invoice owner</h6>
                  <p className="">{`${invoice.owner} (${invoice.bank_account_detail?.account_number})`}</p>
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <h6>Status</h6>
                    <p>{camelizeWithSpaces(invoice.status)}</p>
                  </div>
                  <div className="col-4">
                    <h6>Requested at</h6>
                    <p>{formatDate(invoice.requested_at)}</p>
                  </div>
                  <div className="col-4">
                    <h6>Approved at</h6>
                    <p>{formatDate(invoice.approved_at)}</p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <h6>Value</h6>
                    <p>{formatCurrency(invoice.amount, invoice.currency)}</p>
                  </div>
                  <div className="col-4">
                    <h6>Due date</h6>
                    <p>{formatDate(invoice.due_at)}</p>
                  </div>
                </div>
              </div>
              <h5 className="underline mt-4">Bank account info</h5>
              <div className="info-text flex flex-wrap gap-4">
                <div className="flex-1 mt-3">
                  <h6>Corporate name</h6>
                  <p className="">{invoice.bank_account_detail?.account_name}</p>
                </div>
                <div className="flex-1 mt-3">
                  <h6>Bank</h6>
                  <p className="">{camelizeWithSpaces(invoice.bank_account_detail?.bank_name)}</p>
                </div>
                <div className="row mt-3">
                  <div className="col-4">
                    <h6>Branch</h6>
                    <p>{invoice.bank_account_detail?.bank_branch}</p>
                  </div>
                  <div className="col-4">
                    <h6>Account</h6>
                    <p>{invoice.bank_account_detail?.account_number}</p>
                  </div>
                  <div className="col-4">
                    <h6>Type</h6>
                    <p>{camelizeWithSpaces(invoice.bank_account_detail?.account_type)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default BuyerSupplierInvoiceDetail;
