import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SelectButton } from "primereact/selectbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import brandStore from "../../stores/BrandStore.js";
import formatCurrency from "../helpers/formatCurrency.jsx";
import SupplierInvoiceFee from "../utils/SupplierInvoiceFee.jsx";
import SupplierInvoiceTopSection from "./SupplierInvoiceTopSection.jsx";
import { isNotEmpty } from "../helpers/isEmptyHelpers.jsx";
import ShowAlert from "../utils/Alert.jsx";
import formatPercentage from "../helpers/formatPercentage.jsx";
import apiAxios from "../../axios.js";
import ShowStickyAlert from "../utils/StickyAlert.jsx";
import { camelizeWithSpaces } from "../helpers/camelizeWithSpaces.jsx";

library.add(fas);

SimulateInvoiceSection.propTypes = {
  availableFeePercentages: PropTypes.array,
  defaultFee: PropTypes.number,
  invoice: PropTypes.object,
  fetchInvoice: PropTypes.func,
};

function SimulateInvoiceSection({ availableFeePercentages, defaultFee, invoice, fetchInvoice }) {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const brandLogo = brandStore((state) => state.brand.logo);
  const [selectedFeePercentage, setSelectedFeePercentage] = useState(defaultFee);
  const unknownError = "An unknown error occurred";
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isNotEmpty(defaultFee)) {
      setSelectedFeePercentage(defaultFee);
      setValue("percentage_requested", defaultFee);
    }
  }, [defaultFee, setValue]);

  const getSelectedFee = () => {
    const selectedFeeKey = isNotEmpty(selectedFeePercentage) ? selectedFeePercentage : defaultFee;
    const selectedFee = invoice.simulated_fees.find(fee => fee.percentage_requested === selectedFeeKey);
    return selectedFee ? selectedFee : null;
  };

  const simulatedFeeDetail = (fees) => {
    return (
      <>
        {fees && <SupplierInvoiceFee invoice={invoice} earlyPaymentFees={fees.simulated_fee_list} />}
        <Button
          className="p-button-wide"
          icon={(options) => <FontAwesomeIcon icon={["fas", "right-to-bracket"]}  {...options.iconProps} />}
          type="submit"
          label={`REQUEST ${formatCurrency(fees.expected_amount, invoice.currency)}`}
          rounded
          severity="primary"
        />
      </>
    );
  };

  const onSubmit = () => {
    ShowStickyAlert(
      "",
      `You are about to request ${formatCurrency(getSelectedFee().expected_amount, invoice.currency)} (${formatPercentage(selectedFeePercentage)}). Do you want to proceed?`,
      {
        timer: null,
        showConfirmButton: true,
        confirmButtonText: "Yes, proceed!",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButton: null,
        onConfirm: () => {
          requestEarlyPayment();
        }
      }
    );
  };

  const requestEarlyPayment = () => {
    const formData = new FormData();
    formData.append("percentage_requested", selectedFeePercentage);
    formData.append("invoice_pk", invoice.pk);

    apiAxios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/early_payment_requests`, formData)
      .then((response) => {
        if (isNotEmpty(response.data)) {
          ShowAlert("success", response.data?.message);
          fetchInvoice();
        } else {
          ShowAlert("warning", response?.error);
        }
      })
      .catch((error) => {
        var errorResponse = error.response?.data;
        ShowAlert("error", errorResponse?.error || unknownError);
      });
  };

  const handleViewAllFees = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  const hideDialog = () => {
    if (!visible) {
      return;
    }
    setVisible(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="card card-invoice p-0">
        <div className="card-header">
          <img src={brandLogo} alt="Logo" className="logo-overlay" />
          <p className="m-0">The amount you can get</p>
          <p className="bold-xxl">{formatCurrency(getSelectedFee().expected_amount, invoice.currency)}</p>
        </div>
        <div className="card-body">
          <SupplierInvoiceTopSection invoice={invoice} />
          <h6 className="m-0">Select an option</h6>
          <small>You can only make one early payment request for an invoice.</small>
          <div className="form-group d-flex mt-2">
            <SelectButton
              name="percentage_requested"
              id="percentage_requested"
              {...register("percentage_requested", {
                required: "Percentage requested is required",
              })}
              className={`w-full d-flex justify-content-center mb-3 ${errors.percentage_requested ? "p-invalid" : ""}`}
              onChange={(e) => {
                setSelectedFeePercentage(e.value);
                setValue("percentage_requested", e.value);
              }}
              value={selectedFeePercentage}
              options={availableFeePercentages}
            />
          </div>
          {errors.percentage_requested && (
            <small className="p-error">{errors.percentage_requested.message}</small>
          )}
          <a href="#!" onClick={handleViewAllFees}>View all fees</a>
          <Dialog header={`${invoice.program.name} fees`} visible={visible} onHide={hideDialog}>
            <DataTable value={invoice.program.program_fee.details} >
              <Column field="name" header="Field" body={(rowData) => camelizeWithSpaces(rowData.name)}></Column>
              <Column field="value" header="Value" body={(rowData) => { if (rowData.type === "currency") { return formatCurrency(rowData.value, invoice.program.currency); } else { return formatPercentage(rowData.value); } } }></Column>
              <Column field="description" header="Description"></Column>
            </DataTable>
          </Dialog>
          <hr />
          {simulatedFeeDetail(getSelectedFee())}
        </div>
      </div>
    </form>
  );
};

export default SimulateInvoiceSection;
