import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import apiAxios from "../../../../axios.js";
import { timers } from "../../../constants.jsx";
import { isEmpty } from "../../../helpers/isEmptyHelpers.jsx";

import ShowAlert from "../../../utils/Alert.jsx";
import Header from "../../../includes/Header.jsx";
import Footer from "../../../includes/Footer.jsx";
import LeftMenu from "../../../includes/LeftMenu.jsx";
import ProgramFormStepFees from "../../../forms/ProgramFormStepFees.jsx";
import ProgramFormStepProgramParameters from "../../../forms/ProgramFormStepProgramParameters.jsx";
import ProgramFormStepProgramParticipants from "../../../forms/ProgramFormStepProgramParticipants.jsx";

library.add(fas);

function CreateProgram() {
  const navigate = useNavigate();
  const stepperRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const unknownError = "An unknown error occurred";
  const { register, handleSubmit, setValue, getValues, trigger, watch, control, reset, setError, clearErrors, formState: { errors } } = useForm({
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
    shouldUnregister: false, // keeps the values when component is unmounted
  });

  const stepFields = {
    0: [
      "program[name]",
      "program[program_limit_amount]",
      "program[currency]",
      "program_fee[platform_fee_percentage]",
      "program_fee[program_fee_amount]",
      "program_fee[bank_charge_amount]",
      "program_fee[rebate_percentage]",
      "program_fee[financial_agent_remuneration_percentage]",
      "program_fee[financial_remuneration_percentage]",
    ],
    1: [
      "program[status]",
      "program[credit_payment_days]",
      "program[credit_request_expiration_hours]",
      "program[credit_request_lower_limit_days]",
      "program[credit_request_upper_limit_days]",
      "program[program_type]",
    ],
  };

  /* eslint-disable camelcase*/
  useEffect(() => {
    reset({
      fee_type: "Platform Fee Percentage",
    });
  }, [reset]);
  /* eslint-enable camelcase*/

  const centerToolbarHeaderTemplate = (title) => {
    return (
      <h3>{title}</h3>
    );
  };

  const leftToolbarFooterTemplate = () => {
    return (
      <Button
        label="Back"
        outlined
        rounded
        onClick={() => {
          stepperRef.current.prevCallback();
          setActiveStep(prev => Math.max(0, prev - 1));
        }}
      />
    );
  };

  const rightToolbarFooterTemplate = () => {
    return (
      <Button
        label="Next"
        rounded
        onClick={async () => {
          const fieldsToValidate = stepFields[activeStep] || [];
          const isValid = await trigger(fieldsToValidate);
          if (isValid) {
            stepperRef.current.nextCallback();
            setActiveStep(prev => prev + 1);
          }
        }}
      />
    );
  };

  const rightSubmitToolbarFooterTemplate = () => {
    return (
      <Button
        label="Submit"
        type="submit"
        rounded
      />
    );
  };
  
  const onSubmit = (data) => {
    // remove other fee types from data if one is selected
    const feeType = getValues("fee_type");
    if (feeType === "Platform Fee Percentage") {
      delete data.program_fee?.program_fee_amount;
    } else if (feeType === "Program Fee") {
      delete data.program_fee?.platform_fee_percentage;
    }
    delete data.fee_type;

    apiAxios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/programs`, data)
      .then((response) => {
        var createResponse = response.data;
        if (!isEmpty(createResponse)) {
          ShowAlert("success", "Program created successfully");
          setTimeout(() => {
            navigate("/admin/programs"); 
          }, timers.SHORT_DELAY); 
        } else {
          ShowAlert("warning", response?.error ?? unknownError);
        }
      })
      .catch((error) => {
        ShowAlert("error", error.response?.data?.error || unknownError);
      });
  };

  return (
    <>
      <Header />
      <main className="footer-top-container">
        <div className="container row my-4">
          <LeftMenu />
          <div className="col-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stepper ref={stepperRef} style={{ flexBasis: "50rem" }}>
                <StepperPanel 
                  header="Fees"
                >
                  <Toolbar center={centerToolbarHeaderTemplate("Fees")}></Toolbar>       
                  <div className="flex flex-column">
                    <div className="card p-4">
                      <ProgramFormStepFees
                        register={register}
                        errors={errors}
                        getValues={getValues}
                        setValue={setValue}
                        control={control}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </div>
                  </div>
                  <Toolbar end={rightToolbarFooterTemplate}></Toolbar>  
                </StepperPanel>
                <StepperPanel 
                  header="Program Parameters"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "pen"]}  {...options.iconProps} /> }
                >
                  <Toolbar center={centerToolbarHeaderTemplate("Program Parameters")}></Toolbar>   
                  <div className="flex flex-column my-3">
                    <div className="card">
                      <ProgramFormStepProgramParameters
                        register={register}
                        errors={errors}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                      />
                    </div>
                  </div>
                  <Toolbar start={leftToolbarFooterTemplate} end={rightToolbarFooterTemplate}></Toolbar>  
                </StepperPanel>
                <StepperPanel 
                  header="Program Participants"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "user"]}  {...options.iconProps} /> }
                >
                  <Toolbar center={centerToolbarHeaderTemplate("Program Participants")}></Toolbar>  
                  <div className="flex flex-column">
                    <div className="card">
                      <ProgramFormStepProgramParticipants />
                    </div>
                  </div>
                  <Toolbar start={leftToolbarFooterTemplate} end={rightSubmitToolbarFooterTemplate}></Toolbar>  
                </StepperPanel>
              </Stepper>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default CreateProgram;
