
function Footer() {
  return (
    <footer className="footer">
      <div>
        <div className="footer-links">
          <a href="/terms-conditions" target="_blank">
            Terms and Conditions
          </a>
          <a href="/privacy" target="_blank">
            Privacy Policy
          </a>
          <a href="/fees-explained" target="_blank">
            Fees Explained
          </a>
        </div>
        <div className="footer-copyright">
          Swiftly &copy; {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
