import apiAxios from "../../../axios.js";
import { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import notificationStore from "../../../stores/NotificationStore.js";
import brandStore from "../../../stores/BrandStore.js";
import { timers } from "../../constants.jsx";
import ShowAlert from "../../utils/Alert.jsx";

library.add(fas);


function ProfileOptionsBodyTemplate() {
  const clearNotifications = notificationStore((state) => state.clearNotifications);
  const clearBrand = brandStore((state) => state.clearBrand);
  const menu = useRef(null);
  const [onLogoutSuccessCalled, setOnLogoutSuccessCalled] = useState(false);

  const items = [
    {
      label: "Logout",
      icon: "pi pi-logout",
      command: () => {
        logoutUser();
      }
    }
  ];

  const navigateToLogin = (doubleCallFun) => {
    if (onLogoutSuccessCalled) { return; }
    doubleCallFun(true);
    window.location.replace("/login");
  };

  const resetStorageState = () => {
    clearNotifications();
    clearBrand();
    localStorage.clear();
  };

  const logoutUser = () => {
    apiAxios
      .delete(`${process.env.REACT_APP_BASE_API_URL}/api/v1/logout`)
      .then((_response) => {
        ShowAlert("success", "You have been logged out successfully", () => { navigateToLogin(setOnLogoutSuccessCalled); });
        setTimeout(() => {
          navigateToLogin(setOnLogoutSuccessCalled);
        }, timers.SHORT_DELAY);
        resetStorageState();
      })
      .catch((_error) => {
        ShowAlert("success", "You have been logged out successfully", () => { navigateToLogin(setOnLogoutSuccessCalled); });
        setTimeout(() => {
          navigateToLogin(setOnLogoutSuccessCalled);
        }, timers.SHORT_DELAY);
        resetStorageState();
      });
  };

  return (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button
        icon={(options) => <FontAwesomeIcon icon={["fas", "user"]}  {...options.iconProps} /> }
        onClick={(event) => menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
      />
    </>
  );
}

export default ProfileOptionsBodyTemplate;
