import Footer from "../../includes/Footer.jsx";

const FeesExplained = () => {
  return (
    <>
      <main className="footer-top-container-no-header d-flex justify-content-center align-items-center">
        <div className="container-xl mt-4">
          <div className="card page-content">
            <div className="card-body">
              <div>
                <h1 className="text-center">Fees Explained</h1>

                <h4>Platform Fee</h4>
                <p>
                  The platform fee is a <i>percentage</i> based fee charged for each paid early payment request.
                  It is based on the amount requested.<br />
                  For example:<br />
                  If a user has an invoice for P100 and requests 50% of the invoice for early payment, within a program with a 2% platform fee, they will be charged:<br></br>
                  50 * <sup>2</sup>&frasl;<sub>100</sub> = P1
                </p>

                <h4>Program Fee</h4>
                <p>
                  The program fee is a <i>flat</i> fee charged for each paid early payment request.<br />
                  For example:<br />
                  If a user has an invoice for P100 and requests 50% of the invoice for early payment, within a program with a P2 program fee, they will be charged a flat fee of P2
                </p>

                <h4>Bank Charge Fee</h4>
                <p>
                  The bank charge fee is a <i>flat</i> fee charged for each paid early payment request.<br />
                  For example:<br />
                  If a user has an invoice for P100 and requests 50% of the invoice for early payment, within a program with a P2 bank charge fee, they will be charged a flat fee of P2
                </p>

                <h4>Financial Agent Remuneration Fee</h4>
                <p>
                  The financial agent remuneration fee is a <i>percentage</i> based, prorated fee charged for each paid early payment request.
                  It is based on the amount requested and the number of days to the invoice due date.<br />
                  We use a 30 day accounting window<br /><br />

                  Example 1:<br />
                  If a user has an invoice for P100 and requests 50% of the invoice for early payment 15 days early, within a program with a 2% financial agent remuneration fee, they will be charged:
                  50 * <sup>2</sup>&frasl;<sub>100</sub> * <sup>15</sup>&frasl;<sub>30</sub> = P0.5<br /><br />

                  Example 2:<br />
                  If a user has an invoice for P100 and requests 50% of the invoice for early payment 45 days early, within a program with a 2% financial agent remuneration fee, they will be charged:
                  50 * <sup>2</sup>&frasl;<sub>100</sub> * <sup>45</sup>&frasl;<sub>30</sub> = P1.5
                </p>

                <h4>Invoice Past Due Fee/Financial Agent Late Fee</h4>
                <p>
                  The invoice past due fee is a <i>percentage</i> based, prorated fee charged for paid early payment requests on invoices that are paid late by the offtaker.
                  It is based on the amount requested and the number of days from the invoice due date, taking into account the grace period (also called the credit payment days).<br />
                  We use a 30 day accounting window<br /><br />

                  Example 1:<br />
                  A user has an invoice for P100 and requests 50% of the invoice for early payment, within a program with a 2% invoice past due fee and a 7 day grace period. On the 8th day they will be charged:
                  50 * <sup>2</sup>&frasl;<sub>100</sub> * <sup>1</sup>&frasl;<sub>30</sub> = P0.04 (decimals are rounded up)<br /><br />

                  Example 2:<br />
                  A user has an invoice for P100 and requests 50% of the invoice for early payment, within a program with a 2% invoice past due fee and a 7 day grace period. On the 15th day they will be charged:
                  50 * <sup>2</sup>&frasl;<sub>100</sub> * <sup>8</sup>&frasl;<sub>30</sub> = P0.27 (decimals are rounded up)
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FeesExplained;
