import { Toolbar } from "primereact/toolbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { useParams, useNavigate } from "react-router-dom";

import ShowAlert from "../../../utils/Alert.jsx";
import Header from "../../../includes/Header.jsx";
import { timers, uploads } from "../../../constants.jsx";

library.add(fas);

function UploadPaidInvoices() {
  const { pk } = useParams();
  const navigate = useNavigate();
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const unknownError = "An unknown error occurred";
  const financierDashboardPath = "/financier";

  const leftToolbarHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h1>Upload Invoices</h1>
        <p>Upload a CSV to mark multiple invoices as paid</p>
      </div>
    );
  };

  const onTemplateSelect = (e) => {
    let totalFileSize = totalSize;
    /* eslint-disable prefer-const */
    let files = e.files;
    /* eslint-enable prefer-const */

    totalFileSize = Object.values(files).reduce((sum, file) => sum + (file.size || 0), 0);

    setTotalSize(totalFileSize);
  };

  const onTemplateUpload = (e) => {
    let totalFileSize = 0;
    totalFileSize = Object.values(e.files).reduce((sum, file) => sum + (file.size || 0), 0);    

    setTotalSize(totalFileSize);
    ShowAlert("success", JSON.parse(e.xhr.responseText).message, () => {
      navigate(financierDashboardPath);
    });
    setTimeout(() => {
      navigate(financierDashboardPath);
    }, timers.SHORT_DELAY);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const onTemplateError = (e) => {
    ShowAlert("error", JSON.parse(e.xhr.responseText).error || unknownError, () => {
      navigate(0);
    });
    setTimeout(() => {
      navigate(0);
    }, timers.SHORT_DELAY);
  };

  const onBeforeUpload = (e) => {
    e.formData.append("program_pk", pk);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / uploads.MAX_FILE_SIZE;
    const formattedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : `${uploads.NO_FILE_SIZE} B`;

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formattedValue} / {uploads.MAX_FILE_SIZE_MB} MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    /* eslint-disable react/prop-types */
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon={(options) => <FontAwesomeIcon icon={["fas", "times"]}  {...options.iconProps} /> } 
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
    /* eslint-enable react/prop-types */
  };

  const emptyTemplate = () => {
    return (
      <div className="d-flex flex align-items-center justify-content-center flex-column">
        <FontAwesomeIcon
          className="p-input-icon mt-3 p-5"
          icon={["fas", "image"]}
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        />
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-2"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = { className: "custom-choose-btn p-button-rounded p-button-outlined" };
  const uploadOptions = { className: "custom-upload-btn p-button-success p-button-rounded p-button-outlined" };
  const cancelOptions = { className: "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined" };


  return (
    <>
      <Header />
      <main>
        <div className="container-xl my-4">
          <Toolbar
            className="px-0"
            start={leftToolbarHeaderTemplate}
          ></Toolbar>
          <div className="card">
            <div className="card-body mt-3">
              <FileUpload
                ref={fileUploadRef}
                name="file"
                url={`${process.env.REACT_APP_BASE_APP_URL}/api/v1/financier/program/upload_paid_invoices`}
                accept="text/csv"
                multiple={false}
                maxFileSize={uploads.MAX_FILE_SIZE_BYTES}
                onUpload={onTemplateUpload}
                onSelect={onTemplateSelect}
                onError={onTemplateError}
                onClear={onTemplateClear}
                onBeforeUpload={onBeforeUpload}
                headerTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                uploadOptions={uploadOptions}
                cancelOptions={cancelOptions}
                withCredentials={true}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default UploadPaidInvoices;
