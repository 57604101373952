import { useState } from "react";
import { useParams } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Header from "../../includes/Header.jsx";
import Footer from "../../includes/Footer.jsx";
import brandStore from "../../../stores/BrandStore.js";
import ShowAlert from "../../utils/Alert.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import apiAxios from "../../../axios.js";
import { Calendar } from "primereact/calendar";

library.add(fas);

function FinancierGenerateReport() {
  const { pk } = useParams();
  const noProgramError = "No program found";
  const { handleSubmit, control, formState: { errors } } = useForm();
  const brandLogo = brandStore((state) => state.brand.logo);
  const [startDate, setStartDate] = useState(null);

  const onSubmit = (data) => {
    /* eslint-disable camelcase*/
    data.program_pk = pk;
    /* eslint-enable camelcase*/
    apiAxios
      .post(`${process.env.REACT_APP_BASE_API_URL}/api/v1/financier/program/generate/report`, data, {
        responseType: "blob", headers: {
          "Accept": "text/csv"
        }
      })
      .then((response) => {

        const contentDisposition = response.headers.get("content-disposition");
        const filenameMatch = contentDisposition?.match(/filename="(.+)"/);
        const filename = filenameMatch ? filenameMatch[1] : `invoice_report_${new Date().toISOString()}.csv`;

        const url = window.URL.createObjectURL(new Blob([response.data], { type: "text/csv" }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        ShowAlert("success", "Your report will download automatically soon");
      })
      .catch((error) => {
        ShowAlert("error", error?.response?.data?.error ?? noProgramError);
      });
  };

  return (
    <>
      <Header />
      <main className="footer-top-container">
        <div className="container-md my-4">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card card-invoice p-0">
              <div className="card-header pb-5">
                <img src={brandLogo} alt="Logo" className="logo-overlay" />
              </div>
              <div className="card-body">
                <div className="form-row row mb-4">
                  <div className="form-group col-md-12 mt-3">
                    <div className="d-flex justify-content-around">
                      <label htmlFor="start_date" className="form-label">
                        Start Date
                      </label>
                      <div className="d-flex flex-column">
                        <Controller
                          id="start_date"
                          name="start_date"
                          control={control}
                          rules={{ required: "Supplier type is required" }}
                          render={({ field, fieldState }) => (
                            <Calendar
                              {...field}
                              value={field.value}
                              name="start_date"
                              placeholder="Select start date"
                              onChange={(e) => {
                                field.onChange(e.value);
                                setStartDate(e.value);
                              }}
                              className={`${fieldState.error ? "p-invalid" : ""}`}
                            />
                          )}
                        />
                        {errors.start_date && (
                          <small className="p-error p-1">{errors.start_date.message}</small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12 mt-3">
                    <div className="d-flex justify-content-around">
                      <label htmlFor="end_date" className="form-label">
                        End Date
                      </label>
                      <div className="d-flex flex-column">
                        <Controller
                          id="end_date"
                          name="end_date"
                          control={control}
                          rules={{ required: "Supplier type is required" }}
                          render={({ field, fieldState }) => (
                            <Calendar
                              {...field}
                              value={field.value}
                              minDate={startDate}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              placeholder="Select end date"
                              className={`${fieldState.error ? "p-invalid" : ""}`}
                            />
                          )}
                        />
                        {errors.end_date && (
                          <small className="p-error p-1">{errors.end_date.message}</small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-center">
                  <small className="p-1">search invoices by due date</small>
                </div>
                <Button
                  className="p-button-wide"
                  icon={(options) => <FontAwesomeIcon icon={["fas", "check"]}  {...options.iconProps} />}
                  type="submit"
                  label="Generate Report"
                  rounded
                  severity="primary"
                />
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default FinancierGenerateReport;
