import { Suspense, useState, useEffect } from "react";
import PropTypes from "prop-types";
import apiAxios from "../../../axios.js";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Toolbar } from "primereact/toolbar";

import brandStore from "../../../stores/BrandStore.js";
import Header from "../../includes/Header.jsx";
import Footer from "../../includes/Footer.jsx";
import ListEntityItems from "../../utils/ListEntityItems.jsx";
import ShowAlert from "../../utils/Alert.jsx";
import LoadingFallback from "../../utils/Loading.jsx";

import { isEmpty } from "../../helpers/isEmptyHelpers.jsx";
import { apiWrapper } from "../../common/apiWrapper.js";

library.add(fas);

const FinancierDashboard = () => {
  const noProgramError = "No Programs found";
  const clearBrand = brandStore((state) => state.clearBrand);

  useEffect(() => {
    clearBrand();
  }, [clearBrand]);

  const programsResource = apiWrapper(() =>
    apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/financiers`)
  );

  const ProgramsList = ({ resource }) => {
    const [globalFilter, setGlobalFilter] = useState("");
    let programs = [];
    const response = resource.read();
    if (isEmpty(response?.data)) {
      ShowAlert("warning", response?.error ?? noProgramError);
    } else {
      programs = response.data?.programs;
    }

    const filteredPrograms = programs.filter(program => {
      if (isEmpty(globalFilter.trim())) { return true; }

      const searchTerm = globalFilter.toLowerCase().trim();
      return (
        program?.name?.toLowerCase().includes(searchTerm) ||
        program?.estimated_value_amount?.toString().includes(searchTerm)
      );
    });

    const rightToolbarHeaderTemplate = () => {
      return (
        <>
          <IconField iconPosition="left">
            <FontAwesomeIcon className="p-input-icon" icon={["fas", "magnifying-glass"]} />
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
          </IconField>
        </>
      );
    };

    const leftToolbarHeaderTemplate = () => {
      if (programs.length) {
        return (
          <div className="flex flex-wrap gap-2">
            <h1>Select a program</h1>
          </div>
        );
      } else {
        return (
          <div className="flex flex-wrap gap-2">
            <h1>{noProgramError}</h1>
          </div>
        );
      }
    };

    return (
      <>
        <Toolbar className="px-0" start={leftToolbarHeaderTemplate} end={rightToolbarHeaderTemplate} />
        <ListEntityItems entities={filteredPrograms} entityType="financier_program" />
      </>
    );
  };

  ProgramsList.propTypes = {
    resource: PropTypes.shape({
      read: PropTypes.func.isRequired
    }).isRequired,
  };

  return (
    <>
      <Header />
      <main className="footer-top-container">
        <div className="container-xl my-4">
          <Suspense fallback={<LoadingFallback />}>
            <ProgramsList resource={programsResource} />
          </Suspense>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default FinancierDashboard;
