// App Constants

const currencies = [
  { name: "Botswana Pula", code: "BWP", index: 0 },
  { name: "Zambian Kwacha", code: "ZMW", index: 1 },
  { name: "South African Rand", code: "ZAR", index: 2 },
  { name: "United States Dollar", code: "USD", index: 3 },
];

const statuses = {
  payments: [
    { name: "Pending", index: 0 },
    { name: "Approved", index: 1 },
    { name: "Paid", index: 2 },
    { name: "Declined", index: 3 },
    { name: "Expired", index: 4 },
  ],
  invoices: [
    { name: "Pending", index: 0 },
    { name: "Post Due", index: 1 },
    { name: "Paid", index: 2 },
  ],
  general: [
    { name: "Active", index: 0 },
    { name: "Inactive", index: 1 },
  ],
};

const companyTypes = [
  { name: "Private Company", code: "private_company", index: 0 },
  { name: "Public Company", code: "public_company", index: 1 },
  { name: "Individual", code: "individual", index: 2 },
];

const accountTypes = [
  { name: "Checking", index: 0 },
  { name: "Savings", index: 1 },
  { name: "Business", index: 2 },
  { name: "Personal", index: 3 },
];

const bankNames = [
  { name: "First National Bank", code: "FIRST_NATIONAL_BANK", index: 0 },
  { name: "Absa Bank", code: "ABSA_BANK", index: 1 },
  { name: "Bank Gaborone", code: "BANK_GABORONE", index: 2 },
  { name: "Stanbic Bank", code: "STANBIC_BANK", index: 3 },
  { name: "Standard Chartered Bank", code: "STANDARD_CHARTERED_BANK", index: 4 },
  { name: "Access Bank Botswana", code: "ACCESS_BANK_BOTSWANA", index: 5 },
  { name: "Bank Of Baroda", code: "BANK_OF_BARODA", index: 6 },
  { name: "Bank Of Botswana", code: "BANK_OF_BOTSWANA", index: 7 },
  { name: "First Capital Bank", code: "FIRST_CAPITAL_BANK", index: 8 },
  { name: "BBS Bank", code: "BBS_BANK", index: 9 }
];

const timers = {
  SHORT_DELAY: 3000,
  LONG_DELAY: 3000,
};

const uploads = {
  NO_FILE_SIZE: 0,
  MAX_FILE_SIZE: 50000,
  MAX_FILE_SIZE_MB: 5,
  MAX_FILE_SIZE_BYTES: 5000000,
};

const debounceDelay = 300;

const swiftlyContact = {
  EMAIL: "[Insert Email]",
  PHONE: "[Insert Phone]",
};

export { currencies, statuses, timers, companyTypes, accountTypes, bankNames, debounceDelay, swiftlyContact, uploads };
