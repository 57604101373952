import { useCallback, useEffect, useRef, useState } from "react";
import apiAxios from "../../../../axios.js";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Header from "../../../includes/Header.jsx";
import Footer from "../../../includes/Footer.jsx";
import LeftMenu from "../../../includes/LeftMenu.jsx";
import ShowAlert from "../../../utils/Alert.jsx";
import formatStatus from "../../../helpers/formatStatus.jsx";
import CompanyTypeBodyTemplate from "../../../tables/templates/CompanyTypeBodyTemplate.jsx";
import AdminSupplierCrudActionsBodyTemplate from "../../../tables/templates/AdminSupplierCrudActionsBodyTemplate.jsx";
import SearchFilter from "../../../utils/SearchFilter.jsx";

import { apiWrapper } from "../../../common/apiWrapper.js";
import sortHelper from "../../../helpers/sortHelper.jsx";

library.add(fas);

function ListSupplier(){
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState([]);
  const [supplierRecordCount, setSupplierRecordCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const dataTableReference = useRef(null);
  const noSuppliersError = "No suppliers found";
  const [tableLazyState, setTableLazyState] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {}
  });

  const updateTableLazyState = (newState) => {
    setTableLazyState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const onPage = (event) => {
    updateTableLazyState({
      first: event.first,
      rows: event.rows,
      page: event.page + 1,
    });
  };

  const onSort = (event) => {
    const { sortField, sortOrder } = event;

    updateTableLazyState({
      sortField,
      sortOrder,
    });
  };

  const onSearch = async (searchTerm) => {
    updateTableLazyState({
      first: 0,
      page: 1,
      filters: {
        ...tableLazyState.filters,
        /* eslint-disable camelcase */
        search_term: searchTerm,
        /* eslint-enable camelcase */
      },
    });
  };

  const loadSuppliersTable = useCallback(async () => {
    setLoading(true);
    const requestParams = {
      page: tableLazyState.page,
      pageSize: tableLazyState.rows,
      sortField: tableLazyState.sortField,
      sortOrder: tableLazyState.sortOrder,
      ...tableLazyState.filters
    };

    const supplierDataWrapper = apiWrapper(() =>
      apiAxios.get(`${process.env.REACT_APP_BASE_API_URL}/api/v1/admin/suppliers`, {
        params: requestParams
      })
    );

    try {
      const response = await supplierDataWrapper.read();
      if (response?.data) {
        setSuppliers(response.data?.suppliers || []);
        setSupplierRecordCount(response.data?.suppliers.length || 0);
      }
      setLoading(false);
    } catch (promiseOrError) {
      if (promiseOrError instanceof Promise) {
        promiseOrError.then(async () => {
          try {
            const response = await supplierDataWrapper.read();
            if (response?.data) {
              setSuppliers(response.data?.suppliers || []);
              setSupplierRecordCount(response.data?.suppliers.length || 0);
            }
          } catch (error) {
            setSuppliers([]);
            setSupplierRecordCount(0);
            ShowAlert("warning", error ?? noSuppliersError);
          } finally {
            setLoading(false);
          }
        });
      } else {
        setSuppliers([]);
        setSupplierRecordCount(0);
        setLoading(false);
        ShowAlert("warning", noSuppliersError);
      }
    }
  }, [tableLazyState]);

  useEffect(() => {
    loadSuppliersTable();
  }, [loadSuppliersTable]);

  const sortedSuppliers = sortHelper(suppliers, tableLazyState.sortField, tableLazyState.sortOrder);

  const leftToolbarHeaderTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <h1>Suppliers</h1>
      </div>
    );
  };

  const leftToolbarOptionsTemplate = () => {
    return (
      <SearchFilter onSearch={onSearch} />
    );
  };

  const rightToolbarOptionsTemplate = () => {
    return (
      <Button 
        className="has-icon"
        label="New Supplier"
        icon={(options) => <FontAwesomeIcon icon={["fas", "plus"]} {...options.iconProps} /> }
        rounded
        onClick={() => navigate("/admin/suppliers/create")}
      />      
    );
  };

  return (
    <>
      <Header />
      <main className="footer-top-container">
        <div className="container row my-4">
          <LeftMenu />
          <div className="col-10">
            <div className="card page-content">
              <div className="card-body">
                <Toolbar start={leftToolbarHeaderTemplate}></Toolbar>
                <Toolbar className="mb-3" start={leftToolbarOptionsTemplate} end={rightToolbarOptionsTemplate}></Toolbar>
                <DataTable
                  className="mx-3"
                  ref={dataTableReference}
                  value={sortedSuppliers}
                  dataKey="pk"
                  lazy
                  removableSort
                  paginator
                  rows={tableLazyState.rows}
                  rowsPerPageOptions={[25, 50, 100]}
                  paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  first={tableLazyState.first}
                  totalRecords={supplierRecordCount}
                  onPage={onPage}
                  onSort={onSort}
                  sortField={tableLazyState.sortField}
                  sortOrder={tableLazyState.sortOrder}
                  loading={loading}
                >
                  <Column field="legal_name" header="Name" sortable style={{ minWidth: "15rem" }}></Column>
                  <Column field="supplier_type" header="Type" body={(rowData) => CompanyTypeBodyTemplate(rowData.supplier_type)} style={{ maxWidth: "15rem" }} sortable></Column>
                  <Column field="tax_id" header="Tax ID" sortable style={{ maxWidth: "15rem" }}></Column>
                  <Column field="status" header="Status" body={(rowData) => formatStatus(rowData.status)} sortable style={{ maxWidth: "8rem" }}></Column>
                  <Column body={(rowData) => AdminSupplierCrudActionsBodyTemplate(rowData.pk)} exportable={false} style={{ minWidth: "8rem" }}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ListSupplier;
