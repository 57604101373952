import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import PropTypes from "prop-types";
import { PrimeReactProvider } from "primereact/api";
import brandStore from "./stores/BrandStore.js";

import LoadingFallback from "./components/utils/Loading.jsx";
import "bootstrap/dist/css/bootstrap.css";
import "./primereact-bootstrap.css";

const StyleLoader = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const brand = brandStore((state) => state.brand);
  const BRAND_STYLESHEET_ID = "brand-constants";

  useEffect(() => {
    (async () => {
      const existingConstants = document.getElementById(BRAND_STYLESHEET_ID);
      if (existingConstants) {
        existingConstants.remove();
      }

      try {
        const style = document.createElement("link");
        style.rel = "stylesheet";
        style.id = BRAND_STYLESHEET_ID;
        style.href = brand.theme;

        await new Promise((resolve, reject) => {
          style.onload = resolve;
          style.onerror = reject;

          const firstStylesheet = document.head.querySelector('link[rel="stylesheet"]');
          document.head.insertBefore(style, firstStylesheet);
        });

        setLoaded(true);
      } catch (error) {
        const defaultStyle = document.createElement("link");
        defaultStyle.rel = "stylesheet";
        defaultStyle.id = BRAND_STYLESHEET_ID;
        defaultStyle.href = brand.theme;
        document.head.appendChild(defaultStyle);
        setLoaded(true);
      }
      const indexStyle = document.createElement("link");
      indexStyle.rel = "stylesheet";
      indexStyle.id = "index-style";
      indexStyle.href = `${process.env.PUBLIC_URL}/index.css`;
      document.head.appendChild(indexStyle);
    })();
  }, [brand.theme]);

  if (!loaded) {
    return <LoadingFallback />;
  }

  return children;
};

StyleLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StyleLoader>
      <PrimeReactProvider>
        <App />
      </PrimeReactProvider>
    </StyleLoader>
  </React.StrictMode>
);
